<template>
	<div class="changelog">
		<Transition name="fade" appear>
			<h2>Changelog</h2>
		</Transition>

		<div class="timeline">
			<TransitionGroup appear @before-enter="beforeEnter" @enter="enter">
				<div
					v-for="(change, key, index) in changes"
					:key="change.date"
					class="event"
					:data-index="index"
				>
					<div class="date">
						<p>{{ change.date }}</p>
						<span
							v-tippy
							class="version label purple text-contrast"
							:content="translate('version')"
							>{{ change.version }}</span
						>
					</div>

					<div class="changes">
						<div class="inner">
							<ul>
								<li v-for="(item, i) in change.changes" :key="i">
									<div>
										<p class="description" v-html="item.description"></p>
										<p class="type label" :class="[item.type.toLowerCase()]">{{ item.type }}</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</TransitionGroup>
		</div>
	</div>
</template>

<script>
import changelog from '../../changelog.json'
import gsap from 'gsap'

export default {
	computed: {
		changes() {
			return changelog
		},
	},

	methods: {
		beforeEnter(el) {
			el.style.opacity = 0
			el.style.transform = 'translateY(30px)'
		},

		enter(el, done) {
			gsap.to(el, {
				opacity: 1,
				y: 0,
				duration: 0.8,
				onComplete: done,
				delay: el.dataset.index * 0.1,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.changelog {
	margin: 0 auto;
	max-width: 800px;
	width: 100%;

	h2 {
		text-align: center;
		margin-bottom: 35px;
	}

	.timeline {
		.event {
			align-items: flex-start;
			display: flex;
			position: relative;

			+ .event {
				margin-top: 5px;
			}

			.date {
				background: white;
				font-variant-numeric: tabular-nums;
				font-weight: 600;
				left: 0;
				padding: 14px 0;
				position: absolute;
				text-align: right;
				top: 5px;

				p {
					margin-bottom: 8px;
				}

				span {
					display: inline-block;
					padding: 5px 7px;
				}
			}

			.changes {
				border-left: 4px dotted darken(white, 7.5%);
				margin-left: 66px;
				width: calc(100% - 33px);

				.inner {
					background: darken(white, 5%);
					border-radius: $border-radius;
					margin: 10px 40px 40px;
					padding: 20px;
					position: relative;
					width: calc(100% - 80px);

					&::before {
						$triangle-size: 7px;
						border-top: $triangle-size solid transparent;
						border-bottom: $triangle-size solid transparent;
						border-right: $triangle-size * 1.5 solid darken(white, 5%);
						content: '';
						display: block;
						height: 0;
						left: 0;
						position: absolute;
						transform: translateX(-80%);
						top: 12px;
						width: 0;
					}

					ul {
						li {
							border-radius: $border-radius;
							cursor: default;
							padding: 5px;
							transition: $transition;

							&:hover {
								background: darken(white, 10%);
							}

							> div {
								align-items: flex-start;
								display: flex;
								justify-content: space-between;

								.label {
									background: darken(white, 20%);
									color: $font-color-contrast;
									line-height: 1;
									padding: 5px 8px;

									&.bugfix {
										background: $red;
									}

									&.improvement {
										background: $blue;
									}

									&.feature {
										background: $green;
									}

									&.🥳,
									&.🚀 {
										background: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
